<template>
  <div>
    <transition name="flixFadeIn">
      <div class="flix-container-fluid" style="padding: 0; margin: 0" v-if="open" :key="open">
        <div class="flix-alert flix-alert-success" style="margin: 0;">
          {{ $t('message.verifyText') }} <a href="#" class="flix-html-a flix-text-success" @click.prevent="open = !open"><flixIcon :id="'remove'" /></a>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    data: Object,
    error: Function,
    callback: Function
  },
  data () {
    return {
      open: true
    }
  },
  methods: {
    getAssistent () {
      this.$flix_post({
        url: 'assistent/get_data',
        data: {
          user: this.$route.params.user,
          ID: this.data.form
        },
        callback: function (ret) { if (typeof ret.data[1].autoconfirm === 'undefined' || !ret.data[1].autoconfirm) { return false } this.setAutoconfirm() }.bind(this)
      })
    },
    setAutoconfirm () {
      var send = {
        ID: this.data.ID,
        event_id: this.data.form,
        user: this.$route.params.user,
        status: 'commitment'
      }
      this.data.status = 'commitment'
      this.$flix_post({
        url: 'booking/change_status',
        data: send,
        callback: function () { this.setReminder(send) }.bind(this)
      })
    },
    setReminder (send) {
      this.$flix_post({
        url: 'reminder/change_status',
        data: send,
        callback: function (ret2) {
          this.callback()
        }.bind(this)
      })
    },
    setValidation (callback) {
      var send = {
        user: this.$route.params.user,
        ID: this.data.md5ID
      }
      this.$flix_post({
        url: 'booking/validate',
        data: send,
        callback: function (ret) { callback(ret.data[2]) }
      })
    }
  },
  mounted () {
    this.setValidation(function (ID) {
      if (typeof ID !== 'undefined' && ID && ID !== null && ID !== 'null') {
        this.getAssistent()
      } else {
        this.error()
      }
    }.bind(this))
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
